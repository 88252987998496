import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className="container">
      <div className="glitch" data-text="SNO_SOM">
        SNO_SOM
      </div>
      <div className="glow">SNO_SOM</div>
    </div>
  );
}

export default Footer;
