import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <h3>About us</h3>
      <p>
        sno_som started out as a shard of the collective imagination of talented
        songwriters, composers and musicians. Now it is a guro hentai like music
        flock that will bring you to up the celestial sound forms and bring back
        to the ground with hard breakdown.
      </p>
      <p>
        We're music nomads traversing waveforms in constant search for new and
        exciting sound for our listeners and followers
      </p>
      <p>Let's stay in touch on this long journey!</p>
    </>
  );
};

export default AboutUs;
